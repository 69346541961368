<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('warehouse_config.organization')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="priceInfo.org_id"
                                :options="orgList"
                                id="org_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Fiscal Year" vid="fiscal_year_id"  rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="fiscal_year_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('warehouse_config.fiscal_year')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="priceInfo.fiscal_year_id"
                                :options="fiscalList"
                                id="fiscal_year_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Production Season" vid="production_season_id"  rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="production_season"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('seedsConfig.productionSeason')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="priceInfo.production_season_id"
                                :options="productionSeasonList"
                                id="production_season_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Seed Class" vid="seed_class"  rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="seed_class"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('seedsConfig.seedClass')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="priceInfo.seed_class_id"
                                :options="seedClassList"
                                id="seed_class_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Crop Type" vid="crop_type"  rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="crop_type"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('seedsConfig.cropType')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="priceInfo.crop_type_id"
                                :options="cropTypeList"
                                id="crop_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Crop Name" vid="crop_name_id"  rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="crop_name_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('seedsConfig.cropName')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="priceInfo.crop_name_id"
                                :options="cropNameList"
                                id="crop_name_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                @input="getVarietyInfo(priceInfo.crop_name_id)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Customer Type" vid="customer_id"  rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="customer_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('seedsConfig.customer_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="priceInfo.customer_id"
                                :options="CustomerTypeList"
                                id="customer_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Price Type" vid="price_type_id"  rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="price_type_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('seedsConfig.priceType')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="priceInfo.price_type_id"
                                :options="priceTypeList"
                                id="price_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="Unit Name" vid="measurement_unit_id" rules="required|min_value:1">>
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="measurement_unit_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{$t('seedsSeeds.unitName')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="priceInfo.measurement_unit_id"
                                    :options="unitNameList"
                                    id="measurement_unit_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    disabled
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" md="6" sm="12" xs="12">
                          <ValidationProvider name="Effective date" vid="effective_date" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="effective_date"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('seedsConfig.effective_date')}} <span class="text-danger">*</span>
                              </template>
                              <flat-pickr class="form-control"
                                  v-model="priceInfo.effective_date"
                                  :placeholder="$t('seedsConfig.effective_date')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></flat-pickr>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <div class="col-12"></div>
                        <div class="d-flex justify-content-center" v-if="loader" style="margin: auto;">
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        <table class="table table-bordered" v-else>
                          <tr>
                            <th style="width:15%">{{$t('globalTrans.sl_no')}}</th>
                            <th style="width:50%">{{$t('seedsConfig.variety')}}</th>
                            <th>{{$t('globalTrans.price')}}</th>
                          </tr>
                          <slot v-if="priceInfo.price_varities.length>0">
                            <tr :key="index" v-for="(res,index) in priceInfo.price_varities">
                              <td>{{index+1}}</td>
                              <td>
                                <slot v-if="res.variety">
                                  {{ ($i18n.locale==='bn') ? res.variety.variety_name_bn  :  res.variety.variety_name }}
                                </slot>
                                <slot v-else>
                                  {{ ($i18n.locale==='bn') ? res.variety_name_bn  :  res.variety_name }}
                                </slot>
                              </td>
                              <td>
                                <b-form-input
                                  v-model="res.price"
                                  class="mb-2 mr-sm-2 mb-sm-0 form-control"
                                ></b-form-input>
                              </td>
                            </tr>
                          </slot>
                          <slot v-else>
                            <tr><td colspan="4"><h4 class="text-danger text-center">{{$t('globalTrans.noDataFound')}}</h4></td></tr>
                          </slot>
                        </table>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { priceInfoStore, priceInfoUpdate, varietiesNameWise } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.priceInfo.org_id = this.$store.state.dataFilters.orgId
    if (this.id) {
      const tmp = this.getpriceInfoData()
      this.priceInfo = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      priceInfo: {
        id: '',
        org_id: 0,
        fiscal_year_id: 0,
        production_season_id: 0,
        seed_class_id: 0,
        crop_type_id: 0,
        crop_name_id: 0,
        price_type_id: 0,
        measurement_unit_id: 2,
        customer_id: 0,
        effective_date: null,
        price_varities: []
      },
      cropNameList: [],
      loader: false,
      loading: false,
      search: {
        crop_name_id: 0
      }
    }
  },
  methods: {
    customMizeSelect (data, id) {
      this.priceInfo[id] = data
      if (id === 'crop_type_id') {
        this.priceInfo.crop_name = []
        this.priceInfo.price_varities = []
        this.getCropNameList(this.priceInfo.crop_type_id)
      }
    },
    getpriceInfoData () {
       const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.priceInfo.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${priceInfoUpdate}/${this.id}`, this.priceInfo)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, priceInfoStore, this.priceInfo)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    },
    getCropNameList (croptypeID) {
      const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
      if (croptypeID) {
        return cropNameList.filter(cropName => cropName.crop_type_id === croptypeID)
      }
      return cropNameList
    },
    getVarietyInfo (cropNameId) {
      this.loader = true
      this.search.crop_name_id = cropNameId
      RestApi.getData(seedFertilizerServiceBaseUrl, varietiesNameWise, this.search).then(response => {
        this.priceInfo.price_varities = response.data.map(item => {
          const priceData = {
              price: ''
          }
          return Object.assign({}, item, priceData)
        })
        this.loader = false
      })
    }
  },
  watch: {
    'priceInfo.crop_type_id': function (newVal, oldVal) {
      this.cropNameList = this.getCropNameList(newVal)
    }
  },
  computed: {
    fiscalList: function () {
        const fiscalyearData = this.$store.state.commonObj.fiscalYearList
        return fiscalyearData.map(item => {
            if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
            } else {
            return { value: item.value, text: item.text_en }
            }
        })
    },
    productionSeasonList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.productionSeasonList.filter(item => item.status === 1)
    },
    seedClassList: function () {
       return this.$store.state.SeedsFertilizer.commonObj.seedClassList.filter(item => item.status === 1)
    },
    cropTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
    },
    priceTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.priceTypeList.filter(item => item.status === 1)
    },
    unitNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.unitList
    },
    CustomerTypeList: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Institute' : 'ইনস্টিটিউট' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Farmer' : 'কৃষক' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Dealer' : 'ডিলার' }
      ]
      return list
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    }
  }
}
</script>
