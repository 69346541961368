<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
           <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <table class="col-12 table">
                    <tr>
                        <th style="width: 20%">{{$t('warehouse_config.fiscal_year')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{ ($i18n.locale==='bn') ? $n(priceInfo.fiscal_year.split('-')[0], { useGrouping: false })+'-'+$n(priceInfo.fiscal_year.split('-')[1], { useGrouping: false }) :  priceInfo.fiscal_year  }}</td>
                        <th style="width: 20%">{{$t('seedsConfig.productionSeason')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{ ($i18n.locale==='bn') ? priceInfo.production_season_name_bn  :  priceInfo.production_season_name  }}</td>
                    </tr>
                    <tr>
                        <th style="width: 20%">{{$t('seedsConfig.cropType')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{ ($i18n.locale==='bn') ? priceInfo.crop_type_name_bn  :  priceInfo.crop_type_name  }}</td>
                        <th style="width: 20%">{{$t('seedsConfig.cropName')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{ ($i18n.locale==='bn') ? priceInfo.crop_name_name_bn  :  priceInfo.crop_name_name  }}</td>
                    </tr>
                    <tr>
                      <th style="width: 20%">{{$t('seedsConfig.seedClass')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{ ($i18n.locale==='bn') ? priceInfo.seed_class_bn  :  priceInfo.seed_class  }}</td>
                      <th style="width: 20%">{{$t('seedsConfig.priceType')}}</th>
                      <th style="width: 10%">:</th>
                      <td style="width: 20%">{{ ($i18n.locale==='bn') ? priceInfo.price_type_bn  :  priceInfo.price_type  }}</td>
                    </tr>
                    <tr>
                      <th style="width: 20%">{{$t('seedsConfig.measurementUnit')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{ ($i18n.locale==='bn') ? priceInfo.measurementUnit_name_bn  :  priceInfo.measurementUnit_name  }}</td>
                        <th style="width: 20%">{{$t('seedsConfig.effective_date')}}</th>
                        <th style="width: 10%">:</th>
                        <td style="width: 20%">{{priceInfo.effective_date | dateFormat}}</td>
                    </tr>
                </table>
                <table class="table">
                  <tr>
                    <th style="width:10%">{{$t('globalTrans.sl_no')}}</th>
                    <th style="width:50%">{{$t('seedsConfig.variety')}}</th>
                    <th>{{$t('globalTrans.price')}}</th>
                  </tr>
                  <slot v-if="priceInfo.price_varities.length>0">
                    <tr :key="index" v-for="(res,index) in priceInfo.price_varities">
                      <td>{{index+1}}</td>
                      <td>
                        {{ ($i18n.locale==='bn') ? res.variety.variety_name_bn  :  res.variety.variety_name }}
                      </td>
                      <td>
                        {{ $n(res.price) }}
                      </td>
                    </tr>
                  </slot>
                  <slot v-else>
                    <tr><td colspan="4"><h4 class="text-danger text-center">{{$t('globalTrans.noDataFound')}}</h4></td></tr>
                  </slot>
                </table>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getpriceInfoData()
      this.priceInfo = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.$t('globalTrans.view'),
      priceInfo: {
        id: '',
        fiscal_year_id: 0,
        production_season_id: 0,
        seed_class_id: 0,
        crop_type_id: 0,
        crop_name_id: 0,
        price_type_id: 0,
        effective_date: null,
        price_varities: []
      },
      cropNameList: [],
      loader: false,
      search: {
        crop_name_id: 0
      }
    }
  },
  methods: {
    getpriceInfoData () {
       const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getCropNameList (croptypeID) {
      const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
      if (croptypeID) {
        return cropNameList.filter(cropName => cropName.crop_type_id === croptypeID)
      }
      return cropNameList
    }
  }
}
</script>
